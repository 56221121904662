<!-- <footer></footer> -->


<footer id="colophon" class="site-footer lazy loaded" style="background-image: url(&quot;https://smileehealth.eu/wp-content/uploads/2021/03/smile-footer-1.png&quot;);" data-bg="url('https://smileehealth.eu/wp-content/uploads/2021/03/smile-footer-1.png')" data-was-processed="true">

  <div class="footer-wrap container">
    <div class="logo">
      <img class="lazy loaded" src="https://smileehealth.eu/wp-content/uploads/2021/03/Smile-White-Logo.svg" data-src="https://smileehealth.eu/wp-content/uploads/2021/03/Smile-White-Logo.svg" alt="" style="opacity: 1;" data-was-processed="true">
    </div>

    <div class="footer-items">
      <div class="left">
        <div class="disclaimers">
          <div>
            <img class="lazy loaded" src="https://smileehealth.eu/wp-content/uploads/2021/03/800px-Flag_of_Europe.svg.png" data-src="https://smileehealth.eu/wp-content/uploads/2021/03/800px-Flag_of_Europe.svg.png" alt="" style="opacity: 1;" data-was-processed="true">
            <p>This project has received funding from the European Union’s Horizon 2020 Research and Innovation program, under Grant Agreement nº 101016848. The content of this publication represents the view of the author only and is his/her sole responsibility: it cannot be considered to reflect the views of the European Commission. The European Commission does not accept responsibility for the use that may be made of the information it contains.</p>
          </div>
          <div>
            <img class="lazy loaded" src="https://smileehealth.eu/wp-content/uploads/2021/03/CIHR-White-typography.png" data-src="https://smileehealth.eu/wp-content/uploads/2021/03/CIHR-White-typography.png" alt="" style="opacity: 1;" data-was-processed="true">
            <p>The SMILE Project is supported by the <a href="https://cihr-irsc.gc.ca/e/193.html" target="_blank" rel="noopener">Canadian Institutes of Health Research (CIHR)</a></p>
          </div>
          <div class="social">
            <a href="https://www.linkedin.com/company/smile-horizon-2020-project/?viewAsMember=true" target="_blank">
              <img class="lazy loaded" src="https://smileehealth.eu/wp-content/uploads/2021/03/linkedin.svg" data-src="https://smileehealth.eu/wp-content/uploads/2021/03/linkedin.svg" alt="" style="opacity: 1;" data-was-processed="true">
            </a>
            <a href="https://twitter.com/Smileehealth" target="_blank">
              <img class="lazy loaded" src="https://smileehealth.eu/wp-content/uploads/2021/03/twitter.svg" data-src="https://smileehealth.eu/wp-content/uploads/2021/03/twitter.svg" alt="" style="opacity: 1;" data-was-processed="true">
            </a>
          </div>
        </div>
      </div>

      <div class="subscribe tnp tnp-subscription">
        <h4>Stay updated with our news</h4>
        <form method="post" action="home?na=s" (ngSubmit)="submitSendEmail(signupForm)" #signupForm="ngForm">
          <div class="inputs">
            <input class="tnp-name" type="text" placeholder="Name" name="nn" value="" required="" [(ngModel)]="sendEmail.nn" required #nn = "ngModel" id="inputnn">

            <input class="tnp-profile tnp-profile-1" id="tnp-profile_1" type="text" size="" name="np1" placeholder="Organization" required="" [(ngModel)]="sendEmail.np1" required #np1 = "ngModel" id="inputnp1">

            <input class="tnp-email" placeholder="Email" type="email" name="ne" required="" [(ngModel)]="sendEmail.ne" required #ne = "ngModel" id="inputne" pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$">

            <div class="tnp-field tnp-privacy-field">
              <input type="checkbox" name="ny"  class="tnp-privacy" ngModel required #ny = "ngModel"  (click)="sendEmail.ny = 'on'" [checked]="sendEmail.ny == 'on'">
              <a target="_blank" href="https://smileehealth.eu/privacy-policy">By continuing, you accept the privacy policy</a>
            </div>
            <button name="submit" class="tnp-submit" type="submit" value="Subscribe" style="height: 40px;" [disabled]="!signupForm.valid">Subscribe</button>
          </div>
        </form>
      </div>

    </div>

    <div class="footer-lower">
      <div class="left">
        <h4><span>©</span>SMILE e-health  {{currentYear}}</h4>
        <div class="menu">
          <button (click)="sendToUrl('https://smileehealth.eu/contact')">Contact</button>
          <button (click)="sendToUrl('https://smileehealth.eu/privacy-policy')">Privacy Policy</button>
          <button (click)="sendToUrl('https://solutions.smileehealth.eu/terms-and-conditions')">Platform Terms and Conditions</button>
        </div>
      </div>
      <h4>Powered by <a href="https://trokit.com/" target="_blank">Trokit</a></h4>
    </div>
  </div>

</footer>
