import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Auth } from 'src/app/common/auth';
import { EventEmitterService } from 'src/app/common/page.events';
import { SendEmail } from '../models/send_email.model';
import { UploadService } from '../services/upload.service';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-sidebar',
    templateUrl: "../views/sidebar.component.html",
    styleUrls: ['../styles/sidebar.component.css'],
    host: {
      'class': 'app-sidebar',
    },
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {

  sendEmail = new SendEmail();
  currentYear;


  constructor(private eventEmitterService: EventEmitterService,
              private upload: UploadService,
              private http: HttpClient) {

  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

  submitSendEmail(form) {
    let options = {
      method: 'post',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        city: "",
        country: "",
        email: this.sendEmail.ne,
        extra_fields: [
          {
            id: 1,
            value: this.sendEmail.np1
          }
        ],
        first_name: this.sendEmail.nn,
        gender: null,
        id: null,
        last_name: "",
        lists: [],
        region: "",
        status: "confirmed"
      }),
    }
    console.log(options);
    fetch('https://smileehealth.eu/wp-json/newsletter/v2/subscribers?client_key=e8dfbea2fde717f48927e46cad9e3da263926b09&client_secret=6dcc476b4ae0c4ada415e2396609a04f62bb5d94', options)
      .then(response => response.json())
      .then(data => {console.log(data);  this.sendEmail = new SendEmail();});
  }

  sendToUrl(url) {
    window.open(url,"_blank");
  }

}
