<header>
  <div class="container">
    <div class="header-top">
      <div class="logo">
        <img routerLink="/dashboard" class="lazy loaded" src="https://smileehealth.eu/wp-content/uploads/2021/03/Smile-Full-Color-Original-Logo.svg" data-src="https://smileehealth.eu/wp-content/uploads/2021/03/Smile-Full-Color-Original-Logo.svg" alt="" data-was-processed="true" style="opacity: 1;">
      </div>

      <ul class="menu">
        <li (click)="sendToHome()">Home</li>
        <li routerLink="/dashboard">Dashboard</li>
        <li routerLink="/forum">Forum</li>
        <li routerLink="/apply">Apply</li>
        <li *ngIf="userAuth.isLogged && login" routerLink="/smile/form">Add Solution</li>
        <li *ngIf="userAuth.isLogged && login && userAuth.isAdmin" routerLink="/admin/approve">Requests</li>
        <li *ngIf="userAuth.isLogged && login" (click)="logout()">Log out</li>
        <li *ngIf="!userAuth.isLogged" routerLink="/login">Log in</li>
      </ul>

      <div class="mobile-hamburger" (click)="menuClicked()">
        <svg width="37" height="14" viewBox="0 0 37 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="37" height="2" transform="matrix(1 0 0 -1 0 2)" fill="#411ED6"></rect>
          <rect width="37" height="2" transform="matrix(1 0 0 -1 0 8)" fill="#411ED6"></rect>
          <rect width="37" height="2" transform="matrix(1 0 0 -1 0 14)" fill="#411ED6"></rect>
        </svg>
      </div>

    </div>
  </div>
</header>

<div class="mobile-menu" [ngClass]="{'active':openMobileMenu}">
  <svg (click)="menuClickedClose()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: white;margin-top: 20px;margin-left: 110px;">
    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
  </svg>
  <ul class="menu">
    <li (click)="sendToHome()">Home</li>
    <li (click)="navigateMobile('/dashboard')">Dashboard</li>
    <li (click)="navigateMobile('/apply')">Apply</li>
    <li *ngIf="userAuth.isLogged && login" (click)="navigateMobile('/smile/form')">Form</li>
    <li (click)="navigateMobile('/forum')">Forum</li>
    <li *ngIf="!userAuth.isLogged" routerLink="/login">Log in</li>
    <li *ngIf="userAuth.isLogged && login && userAuth.isAdmin" (click)="navigateMobile('/admin/approve')">Approve</li>
    <li *ngIf="userAuth.isLogged && login" (click)="logout()">Log out</li>
  </ul>
</div>
