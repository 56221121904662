<nav class="nav-wrapper">
  <a><img routerLink="/dashboard" style="cursor: pointer;" src="../../../assets/smile/logo.png" alt=""></a>
</nav>
<div class="page-container">
  <div class="form-box-wrapper">
    <div class="form-box">
      <div class="form__head text-white">
        <h2>Forgot password?</h2>
        <p>Enter your e-mail to recieve a confirmation link</p>
      </div>
      <div class="form__body">
        <form action="" [formGroup]="userEmail">
            <div class="input-group">
              <input placeholder="Email..." [class.is-invalid] = "userEmail.get('email').invalid && userEmail.get('email').touched" formControlName="email" class="form-control" type="email" id="email-for-pass" required="" #email required pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$" [(ngModel)]="emailValue">
            </div>
            <div *ngIf="(primEmail.invalid && primEmail.touched) || primEmail.dirty">
              <small *ngIf="primEmail.errors?.required" class="text-danger">{{ 'email is required' | translate | titlecase }}</small>
              <small *ngIf="primEmail.errors?.pattern" class="text-danger">{{ 'please provide a valid email address' | translate | titlecase }}</small>
            </div>
            <button class="form__submit text-white" [ngStyle]="{'display' : !displayForgetPassword ? 'none' : ''}" name="submit" type="submit" value="Submit" [disabled]="!userEmail.valid" (click)="getEmail()">SEND RESET LINK</button>
        </form>
      </div>
      <div class="form__footer">
        <p class="text-white"><a class="text-white">Go back to </a> <a routerLink="/login" class="text-underline text-white">Login</a></p>
      </div>
    </div>
    <div class="form-illustration">
      <img src="../../../assets/smile/Two factor authentication-rafiki 1.svg" alt="">
    </div>
  </div>
</div>
<img src="../../../assets/smile/bg.png" alt="" class="bg-img">
<footer></footer>
