import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators  } from '@angular/forms';

@Component({
    selector: 'app-reset-password',
    templateUrl: '../views/reset-password.component.html',
    styleUrls: ['../styles/reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {

  firstPassword;
  secondPassword;
  resetPasswordToken;

  constructor(private router: Router,
      private auth: AuthService) {

  }

  ngOnInit() {
    this.resetPasswordToken = JSON.parse(localStorage.getItem('token'));
    console.log(this.resetPasswordToken.id);
  }

  userPassword = new FormGroup({
    password: new FormControl('',[
      Validators.required,
      Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$")]),
    secondPass: new FormControl('',[
      Validators.required,
      Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$")]),
  });

  get primPassword(){
    return this.userPassword.get('password')
  }

  get secPassword(){
    return this.userPassword.get('secondPass')
  }

  resetPassword(password) {
    console.error(password);
    this.auth.resetPassword({ password: password }).subscribe(response => {
      this.router.navigate(['/login'], { queryParams: {confirm:1 } });
      localStorage.removeItem('token');
    });
  }

}
