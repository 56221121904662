import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-newsletters-token',
    templateUrl: '../views/newsletters-token.component.html',
    styleUrls: ['../styles/newsletters-token.component.css'],
})
export class NewslettersTokenComponent implements OnInit {

  private routeSub: Subscription;
  constructor(private router: Router,
      private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) //log the entire params object
      console.log(params['id']) //log the value of id
      localStorage.setItem('token-for-newsletters', JSON.stringify(params['id']));
      this.router.navigate(['/newsletters']);
    });
  }


}
