<nav class="nav-wrapper">
  <a><img routerLink="/dashboard" style="cursor: pointer;" src="../../../assets/smile/logo.png" alt=""></a>
</nav>
<div class="page-container">
  <div class="form-box-wrapper">
    <div class="form-box">
      <div class="form__head text-white">
        <h2>Register now</h2>
        <p>Fill the information to register into the platform</p>
      </div>
      <div class="form__body">
        <form action="" role="form" (ngSubmit)="submit(signupForm)" #signupForm="ngForm">
            <div class="input-group">
              <input placeholder="Name..." type="text" required="" class="form-control" [(ngModel)]="registerUser.name" required name="name" #name = "ngModel" id="inputName">
              <div *ngIf="name.invalid && ( name.touched)" class="col-sm-12 text-danger">
                <small *ngIf = "name.errors?.required">
                  Name field can't be blank
                </small>
              </div>
            </div>
            <div class="input-group">
              <input placeholder="Email..." name="email" type="email" #email = "ngModel" required class="form-control" [(ngModel)]="registerUser.email" pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$">
              <div *ngIf="(email.invalid && email.touched) || email.dirty">
                <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                <small *ngIf="email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
              </div>
            </div>
            <div class="input-group">
              <input placeholder="Password..." name="password" #password = "ngModel" type="password" class="form-control" required [(ngModel)]="registerUser.password"  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$" required>
              <div *ngIf="(password.invalid && password.touched) || password.dirty">
                <p *ngIf="password.errors?.required" class="text-danger">Password is required</p>
                <p *ngIf="password.errors?.pattern" class="text-danger">
                  <small *ngIf="password.length<8">Password should have minimum 8 characters, at least 1 uppercase letter, at least 1 lowercase letter, at least 1 number, at least 1 character</small>
                </p>
              </div>
            </div>
            <div class="input-group">
              <input placeholder="Phone..." name="phone" #phone = "ngModel" type="text" class="form-control" required [(ngModel)]="registerUser.phone" required>
              <div *ngIf="(phone.invalid && phone.touched) || phone.dirty">
                <p *ngIf="phone.errors?.required" class="text-danger">Phone is required</p>
              </div>
            </div>
            <div class="input-group">
              <input placeholder="Website..." name="website" #website  = "ngModel"type="text" class="form-control" required [(ngModel)]="registerUser.address" required>
              <div *ngIf="(website.invalid && website.touched) || website.dirty">
                <p *ngIf="website.errors?.required" class="text-danger">Website is required</p>
              </div>
            </div>
            <div class="input-group input-group--double">
              <input placeholder="City..." name="city" #city = "ngModel" type="text" class="form-control" required [(ngModel)]="registerUser.city" required>
              <input placeholder="Country..." name="country" #country = "ngModel" type="text" class="form-control" required [(ngModel)]="registerUser.state" required>
              <div *ngIf="(city.invalid && city.touched) || city.dirty">
                <p *ngIf="city.errors?.required" class="text-danger">City is required</p>
              </div>
              <div *ngIf="(country.invalid && country.touched) || country.dirty">
                <p *ngIf="country.errors?.required" class="text-danger">Country is required</p>
              </div>
            </div>
            <button name="submit" class="form__submit text-white" type="submit" value="Submit" [disabled]="!signupForm.valid">REGISTER</button>
        </form>
      </div>
      <div class="form__footer">
        <p class="text-white"><a class="text-white">Have an account?</a> <a routerLink="/login" class="text-underline text-white"> Login</a></p>
      </div>
    </div>
    <div class="form-illustration">
      <img src="../../../assets/smile/Two factor authentication-rafiki 1.svg" alt="">
    </div>
  </div>
</div>
<img src="../../../assets/smile/bg.png" alt="" class="bg-img">
<footer></footer>
