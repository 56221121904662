<nav class="nav-wrapper">
  <a><img routerLink="/dashboard" style="cursor: pointer;" src="../../../assets/smile/logo.png" alt=""></a>
</nav>
<div class="page-container">
  <div class="form-box-wrapper">
    <div class="form-box">
      <div class="form__head text-white">
        <h2>Welcome back</h2>
        <p>Sign in to your account</p>
      </div>
      <div class="form__body">
        <form action="">
            <div class="input-group">
              <input type="email" placeholder="Email..." name="email" required="" #email onpaste="return false;" ondrop="return false;" autocomplete="off" [(ngModel)]="emailValue" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="input-group">
              <input type="password" placeholder="Password.."  name="password" required=""  #password [(ngModel)]="passwords" onpaste="return false;" ondrop="return false;" autocomplete="off">
            </div>
            <button class="form__submit text-white" (click)="singInClicked(email.value, passwords)">Login</button>
        </form>
      </div>
      <div class="form__footer">
        <p class="text-white"><a routerLink="/register" class="text-white">Create account</a> or <a routerLink="/forget-password" class="text-underline text-white">Reset your password</a></p>
      </div>
    </div>
    <div class="form-illustration">
      <img src="../../../assets/smile/Two factor authentication-rafiki 1.svg" alt="">
    </div>
  </div>
</div>
<img src="../../../assets/smile/bg.png" alt="" class="bg-img">
<footer></footer>
