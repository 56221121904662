export class Url {

  // url = 'http://localhost:3000'

  public static apiUrl: string = 'https://api.smileehealth.eu/api';
  public static apiImagePath: string = 'https://api.smileehealth.eu/images/';
  public static apiImageUpload: string = 'https://api.smileehealth.eu/upload';
  public static apiImageUploadEditor: string = 'https://api.smileehealth.eu/upload/editor';
  public static apiFilePath: string = 'https://api.smileehealth.eu/file/';
}
