export class currentUser {
  id = null;
  name = null;
  email = null;
  password = null;
  role = null;
  attemps = null;
  phone = null;
  address = null;
  city = null;
  state = null;
  lang = null;
  token = null;
}
