import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { currentUser } from '../models/currentUser.model';
import { UploadService } from 'src/app/cms/layouts/services/upload.service';


@Component({
    selector: 'app-newsletters',
    templateUrl: '../views/newsletters.component.html',
    styleUrls: ['../styles/newsletters.component.css'],
})
export class NewslettersComponent implements OnInit {


  tokenForNewsletters;

  constructor(private router: Router,
      private route: ActivatedRoute,
      private auth: AuthService,
      private upload: UploadService) {

  }

  ngOnInit() {
    this.tokenForNewsletters = JSON.parse(localStorage.getItem('token-for-newsletters'));
    console.log(this.tokenForNewsletters.id);
    localStorage.removeItem('token-for-newsletters');

    this.confirmNewsletters();
  }

  confirmNewsletters() {

  }


}
