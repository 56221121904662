import { AdminGuard } from './../common/admin.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { CmsComponent } from './cms.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../common/auth.guard';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';


const routes: Routes = [
  {
    'path': '',
    'component': CmsComponent,
    'children': [
      {
        'path': 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        'path': 'apply',
        loadChildren: () => import('./pages/apply/apply.module').then(m => m.ApplyModule)
      },
      {
        'path': 'forum',
        loadChildren: () => import('./pages/forum/forum.module').then(m => m.ForumModule)
      },
      {
        'path': 'forum/:id',
        loadChildren: () => import('./pages/forum-id/forum-id.module').then(m => m.ForumIDModule)
      },
      {
        'path': 'solutions/:id',
        loadChildren: () => import('./pages/single-sulotion/single-sulotion.module').then(m => m.SingleSulotionModule)
      },
      {
        'path': 'terms-and-conditions',
        loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
      },
      {
        'path': '',
        'redirectTo': 'dashboard',
        'pathMatch': 'full'
      },
    ]
  },
  {
    'path': 'smile',
    'component': CmsComponent,
    'canActivate': [AuthGuard],
    'children': [
      {
        'path': 'form',
        loadChildren: () => import('./pages/form/form.module').then(m => m.FormModule)
      },
      {
        'path': '',
        'redirectTo': 'form',
        'pathMatch': 'full'
      },
    ]
  },
  {
    'path': 'admin',
    'component': CmsComponent,
    'canActivate': [AdminGuard],
    'children': [
      {
        'path': 'approve',
        loadChildren: () => import('./pages/admin-approve-sulotions/admin-approve-sulotions.module').then(m => m.AdminApproveModule)
      },
      {
        'path': 'approve/solutions/:id',
        loadChildren: () => import('./pages/admin-approve-single-sulotions/admin-approve-single-sulotion.module').then(m => m.AdminSingleSulotionModule)
      },
      {
        'path': '',
        'redirectTo': 'approve',
        'pathMatch': 'full'
      },
    ]
  },
  {
    'path': '**',
    'redirectTo': '404',
    'pathMatch': 'full'
  }
];

@NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class CmsRoutingModule { }
