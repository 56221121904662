import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './components/forget-password.component';
import { LoginComponent } from './components/login.component';
import { NewslettersTokenComponent } from './components/newsletters-token.component';
import { NewslettersComponent } from './components/newsletters.component';
import { RegisterComponent } from './components/register.component';
import { ResetPasswordTokenComponent } from './components/reset-password-token.component';
import { ResetPasswordComponent } from './components/reset-password.component';


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'forget-password', component: ForgetPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'reset-password/:id', component: ResetPasswordTokenComponent },
    { path: 'newsletters', component: NewslettersComponent },
    { path: 'newsletters-confirm/:id', component: NewslettersTokenComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
