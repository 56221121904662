<nav class="nav-wrapper">
  <a><img routerLink="/dashboard" style="cursor: pointer;" src="../../../assets/smile/logo.png" alt=""></a>
</nav>
<div class="page-container">
  <div class="form-box-wrapper">
    <div class="form-box">
      <div class="form__head text-white">
        <h2>Reset Password</h2>
        <p>Enter your new password below</p>
      </div>
      <div class="form__body">
        <form action="" [formGroup]="userPassword">
            <div class="input-group">
              <input placeholder="Password..." [(ngModel)]="firstPassword" [class.is-invalid] = "userPassword.get('password').invalid && userPassword.get('password').touched" formControlName="password" required type="password" class="form-control" name="site" required="" required autofocus="" onpaste="return false;" ondrop="return false;" autocomplete="off" #passwordValue>
            </div>
            <div *ngIf="(primPassword.invalid && primPassword.touched) || primPassword.dirty">
              <p *ngIf="primPassword.errors?.required" class="text-danger">Password is required</p>
              <p *ngIf="primPassword.errors?.pattern" class="text-danger">
                <small *ngIf="firstPassword.length<8">Password should have minimum 8 characters, at least 1 uppercase letter, at least 1 lowercase letter, at least 1 number, at least 1 character</small>
              </p>
            </div>
            <div class="input-group">
              <input placeholder="Confirm password..." [(ngModel)]="secondPassword" [class.is-invalid] = "userPassword.get('secondPass').invalid && userPassword.get('secondPass').touched" formControlName="secondPass" required type="password" class="form-control" name="site" required="" required autofocus="" onpaste="return false;" ondrop="return false;" autocomplete="off">
            </div>
            <div *ngIf="(secPassword.invalid && secPassword.touched) || secPassword.dirty">
              <small *ngIf="firstPassword !== secondPassword" class="text-danger">Passwords are not the same</small>
            </div>
            <button class="form__submit text-white" name="submit" type="submit" value="Submit" [disabled]="!userPassword.valid || (firstPassword !== secondPassword)" [ngStyle]="{'cursor' : !userPassword.valid || (firstPassword !== secondPassword) ? 'not-allowed' : 'pointer'}" (click)="resetPassword(passwordValue.value)">RESET PASSWORD</button>
        </form>
      </div>
      <div class="form__footer">
        <p class="text-white"><a class="text-white"></a> <a routerLink="/login" class="text-underline text-white">Login</a></p>
      </div>
    </div>
    <div class="form-illustration">
      <img src="../../../assets/smile/Two factor authentication-rafiki 1.svg" alt="">
    </div>
  </div>
</div>
<img src="../../../assets/smile/bg.png" alt="" class="bg-img">
<footer></footer>
