import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { currentUser } from '../models/currentUser.model';


@Component({
    selector: 'app-register',
    templateUrl: '../views/register.component.html',
    styleUrls: ['../styles/register.component.css'],
})
export class RegisterComponent implements OnInit {


  registerUser = new currentUser();

  constructor(private router: Router,
      private route: ActivatedRoute,
      private auth: AuthService) {

  }

  ngOnInit() {

  }


  submit(signupForm) {
    this.registerUser.role = 'student';
    this.auth.postUser(this.registerUser).subscribe(response => {
      console.log(response);
      this.router.navigate(['/login'], { queryParams: {newAccount: true } });
    });
  }
}
